export const links = [
  {
    id: 1,
    url: 'hero',
    text: 'Home',
  },
  {
    id: 2,
    url: 'projects',
    text: 'Projects',
  },
  {
    id: 3,
    url: 'experience',
    text: 'Experience',
  },
  {
    id: 4,
    url: 'about',
    text: 'About',
  },
];
